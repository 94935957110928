.manual_view {
  background: #fff;
  border-radius: 50%;
  padding: 0.5rem 0.8rem;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  position: fixed;
  right: 2rem;
  bottom: 14.2rem;
  z-index: 9999;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.3);
  color: #004d9f;
  cursor: pointer;
}
.manual_view:hover span {
  opacity: 1;
  left: -8rem;
}
.manual_view span {
  opacity: 0;
  position: absolute;
  left: 8rem;
  top: 0.5rem;
  transition: all .75s ease;
  white-space: nowrap;
  background: white;
  font-weight: bold;
  padding: .3rem;
  border-radius: 1rem;
}
.manual_view:after {
  content: '\f02d';
  display: inline-flex;
  font-size: 1rem;
  font-family: FontAwesome;
}
.ReactModal__Overlay {
  color: black;
  z-index: 9999999;
}
@media screen and (max-width: 1200px) {
  .manual_view {
    right: 1rem;
    bottom: 4rem;
    padding: 1rem 1.5rem;
  }
  .manual_view:hover span {
    opacity: 0;
    left: 8rem;
  }
  .manual_view:after {
    font-size: 2rem;
  }
}
.bike-legal-info {
  background: white;
  padding: 1rem 1.5rem;
  color: black;
  text-align: center;
}
.bike-legal-info p {
  font-size: 14px;
  margin-bottom: 0;
}
